// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bl_kr";
export var caseStudyBackgroundContainer = "bl_kC";
export var caseStudyBackgroundContainer__ship = "bl_kD";
export var caseStudyBackground__bgColor = "bl_kn";
export var caseStudyBackground__lineColor = "bl_kp";
export var caseStudyHead__imageWrapper = "bl_kk";
export var caseStudyProjectsSection = "bl_ks";
export var caseStudyQuote__bgLight = "bl_kt";
export var caseStudyQuote__bgRing = "bl_km";
export var caseStudySolution__ring = "bl_ky";
export var caseStudySolution__ringTwo = "bl_kz";
export var caseStudyTech = "bl_kF";
export var caseStudy__bgDark = "bl_kh";
export var caseStudy__bgLight = "bl_kg";